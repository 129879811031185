import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Input,
  Button,
  FormControl,
  Image,
  Stack,
  Select,
  CheckIcon,
  Text,
  HStack,
  Checkbox,
  Box,
  Heading,
} from "native-base";
import bg from "../assets/background.png";
import styled from "styled-components";
import logo from "../assets/logo.svg";
import telco from "../../src/assets/telco-button.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const paymentFrequencies = [
  {
    label: "One-Time",
    value: "one-time",
  },
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Quarterly",
    value: "quarterly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
];

const formatDate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month}-${day} `;
};

const setEndDate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const finalYear = year + 50;
  return `${finalYear}-${month}-${day} `;
};

const setDebitDay = () => {
  var date = new Date();

  var currentDate = new Date();
  var NextDate = new Date(
    date.getFullYear().toString(),
    date.getMonth() + 2,
    0
  );

  if (
    currentDate.getDate() > NextDate.getDate() &&
    (NextDate.getDate() === 28 || NextDate.getDate() === 29)
  ) {
    return currentDate.getDate() - 3;
  } else if (currentDate.getDate() > NextDate.getDate()) {
    return currentDate.getDate() - 1;
  }
  return currentDate.getDate();
};

export default function PaymentPage() {
  const location = useLocation();
  const history = useHistory();
  const [policyDetails] = useState(location.state ? location.state : []);

  let policies = policyDetails;

  const [policy, setPolicy] = useState("");
  const [product, setProduct] = useState("");
  const [fullname, setFullName] = useState("");
  const [expectedPremiumAmount, setExpectedPremiumAmount] = useState("");

  //   * typed form data
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentFrequency, setPaymentFrequency] = useState("");
  const [phone, setPhone] = useState("");
  const [tnc, setTnc] = useState(false);

  // * const extra data
  const startDate = formatDate(new Date());
  const endDate = setEndDate(new Date());
  const frequency = 1;
  const debitDay = setDebitDay();

  React.useEffect(() => {
    // console.log("debut day is", setDebitDay(31, 29));
  }, []);

  //  * validation
  const validateFields = () => {
    if (
      policy === "" ||
      email === "" ||
      phone === "" ||
      amount === "" ||
      paymentFrequency === ""
    ) {
      toast.warn("Fields cannot be empty!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else if (
      email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) === null
    ) {
      toast.warn("Please enter a valid email!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else if (phone.match(/^[2][3][3]\d{9}$/) === null) {
      toast.warn(
        "Please your phone number should be 12 digits eg. 233240000000",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (tnc !== true) {
      toast.warn(
        "Please accept terms and conditions by selecting the above check box",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (parseInt(amount) < parseInt(expectedPremiumAmount)) {
      toast.warn(
        "Your amount should be equal or more to your expected premium",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }
    // just to check my data

    const pathParams = new URLSearchParams({
      policynum: policy,
      email: email,
      amount: amount,
    });

    // If paymen is one time fire paystack
    if (paymentFrequency === "one-time") {
      window.open(
        "https://app.pinnaclelife.com.gh/DonewellLife/Pay?" + pathParams,
        "_self"
      );
      return;
    }

    // alert to control payment
    Swal.fire({
      title: "SELECT PAYMENT TYPE",
      // text: "You can pay make a one-time payment or select recurring payment to create a payment mandate",
      icon: "question",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonColor: "#5e7c04",
      denyButtonColor: "#005F6B",
      cancelButtonColor: "#d33",
      confirmButtonText: "Recurring Payment",
      denyButtonText: "One-time Payment",
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: "Enter Your phone number in the format 233240000000 to get an OTP",
          input: "text",
          imageUrl:
            "https://raw.githubusercontent.com/phwed/ASSETS-REPO/a741daa08b40a8c72e892da6eaf779bbe464d58b/dl/autodebit.png",
          imgageWidth: 100,
          imageHeight: 100,
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Get OTP",
          confirmButtonColor: "#5e7c04",
          cancelButtonColor: "#d33",
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          preConfirm: (value) => {
            if (!value) {
              Swal.showValidationMessage(`Please provide a phone number`);
              return;
            } else if (value !== phone) {
              Swal.showValidationMessage(
                `Your mobile number does not match, the number should be in the format 233240000000`
              );
              return;
            }

            return fetch(
              `https://app.pinnaclelife.com.gh/DonewellLife/GetValidationCode?momoNumber=${value}`
            )
              .then((response) => {
                if (!response.ok) {
                  throw new Error(response.statusText);
                }
                return response.json();
              })
              .catch((error) => {
                Swal.showValidationMessage(`Request failed: ${error}`);
                console.log(error);
              });
          },
        }).then((result) => {
          if (result.isConfirmed) {
            if (result.value.success === false) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
              return;
            }
            Swal.fire({
              title: "OTP",
              text: "Enter your OTP to validate your payment",
              icon: "info",
              input: "text",
              inputAttributes: {
                autocapitalize: "off",
              },
              showCancelButton: true,
              confirmButtonText: "Validate OTP",
              confirmButtonColor: "#5e7c04",
              cancelButtonColor: "#d33",
              showLoaderOnConfirm: true,
              allowOutsideClick: false,
              preConfirm: (value) => {
                console.log(
                  `https://app.pinnaclelife.com.gh/DonewellLife/ValidateMomonumber?code=${value}&momoNumber=${phone}`
                );

                return fetch(
                  `https://app.pinnaclelife.com.gh/DonewellLife/ValidateMomonumber?code=${value}&momoNumber=${phone}`,
                  { method: "POST" }
                )
                  .then((response) => {
                    if (!response.ok) {
                      throw new Error(response.statusText);
                    }
                    return response.json();
                  })
                  .catch((error) => {
                    Swal.showValidationMessage(`Request failed: ${error}`);
                    console.log(error);
                  });
              },
            }).then((result) => {
              if (result.isConfirmed) {
                console.log(result);
                if (result.value.success === false) {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Your code was incorrect!",
                  });
                  return;
                }
                Swal.fire({
                  title: `Are you sure you want ${amount} GHS to be deducted monthly from your wallet?`,
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonText: "Make Payment",
                  confirmButtonColor: "#5e7c04",
                  cancelButtonColor: "#d33",
                  showLoaderOnConfirm: true,
                  allowOutsideClick: false,
                  preConfirm: (value) => {
                    const pathParams = new URLSearchParams({
                      clientPhone: phone,
                      amount: amount,
                      frequencyType: paymentFrequency,
                      frequency: frequency,
                      startDate: startDate,
                      endDate: endDate,
                      debitDay: debitDay,
                      policyNumber: policy,
                      email: email,
                      product: product,
                      fname: policies[0].firstName,
                      lname: policies[0].lastName,
                      Isverify: "no",
                    });

                    return fetch(
                      `https://app.pinnaclelife.com.gh/DonewellLife/CreateCustomerMandate?${pathParams}`
                    )
                      .then((response) => {
                        if (!response.ok) {
                          throw new Error(response.statusText);
                        }
                        return response.json();
                      })
                      .catch((error) => {
                        Swal.fire({
                          title: `${error.responseMessage}`,
                          icon: "error",
                        });
                        console.log(error);
                      });
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    console.log(result);
                    if (result.value.data.responseCode === "03") {
                      Swal.fire({
                        title: `SUCCESS`,
                        icon: "success",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          history.push("/payment-complete");
                        }
                      });
                      return;
                    }

                    Swal.fire({
                      title: `${result.value.data.responseMessage}`,
                      icon: "error",
                    });
                  }
                });
              }
            });
          }
        });
      } else if (result.isDenied) {
        window.open(
          "https://app.pinnaclelife.com.gh/DonewellLife/Pay?" + pathParams,
          "_self"
        );
      }
    });
  };

  // @param helper functions
  const setDetailOnPolicySelect = (a = [], value) => {
    const index = a.findIndex((i) => i.policyNumber === value);
    setProduct(a[index].product);
    setFullName(a[index].fullName);
    setExpectedPremiumAmount(a[index].basicPremium);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "4vh",
        paddingBottom: "4vh",
      }}
    >
      <BackgroundImage />

      <Box
        w={["99%", "90%", "80%", "60%"]}
        bg="white.500"
        rounded={5}
        shadow={5}
        px={5}
        py={10}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={logo}
          alt="logo"
          accessibilityLabel="logo"
          w="40vh"
          h="10vh"
          resizeMode="contain"
        />

        <Heading mt={5} fontSize="2xl">
          ONLINE PAYMENT
        </Heading>
        {/* <Text>
          Kindly set a new secret code/pin that would be used to verify your
          statement request
        </Text> */}

        <Stack w={["99%", "80%", "70%", "50%"]} mt={10} space={4}>
          <Select
            selectedValue={policy}
            minWidth="200"
            accessibilityLabel="Choose Policy Number"
            placeholder="Choose Policy Number"
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size="5" />,
            }}
            mt={1}
            onValueChange={(itemValue) => {
              setPolicy(itemValue);
              setDetailOnPolicySelect(policies, itemValue);
            }}
          >
            {policies.map((item) => (
              <Select.Item
                label={item.policyNumber}
                value={item.policyNumber}
              />
            ))}
          </Select>

          <FormControl isReadOnly>
            <FormControl.Label _text={{ bold: true }}>
              Full Name
            </FormControl.Label>
            <Input value={fullname} />
          </FormControl>

          <FormControl isReadOnly>
            <FormControl.Label _text={{ bold: true }}>
              Product
            </FormControl.Label>
            <Input value={product} />
          </FormControl>

          <FormControl isReadOnly>
            <FormControl.Label _text={{ bold: true }}>
              Expected Premium Amount (GHS)
            </FormControl.Label>
            <Input value={expectedPremiumAmount} />
          </FormControl>

          <FormControl isRequired>
            <FormControl.Label _text={{ bold: true }}>Email</FormControl.Label>
            <Input
              value={email}
              placeholder="example@example.com"
              onChangeText={(value) => setEmail(value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormControl.Label _text={{ bold: true }}>
              Amount (GHS)
            </FormControl.Label>
            <Input
              value={amount}
              placeholder={expectedPremiumAmount}
              onChangeText={(value) => setAmount(value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormControl.Label _text={{ bold: true }}>
              Payment Frequency
            </FormControl.Label>
            <Select
              selectedValue={paymentFrequency}
              minWidth="200"
              accessibilityLabel="Choose Payment Frequency"
              placeholder="Choose Payment Frequency"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => setPaymentFrequency(itemValue)}
            >
              {paymentFrequencies.map((item) => (
                <Select.Item label={item.label} value={item.value} />
              ))}
            </Select>
            <FormControl.HelperText _text={{ fontSize: "xs" }}>
              Would you like us to create a recurring payment mandate for you?
            </FormControl.HelperText>
          </FormControl>

          <FormControl isRequired>
            <FormControl.Label _text={{ bold: true }}>
              Mobile Number
            </FormControl.Label>
            <Input
              value={phone}
              placeholder="233240000000"
              onChangeText={(value) => setPhone(value)}
            />
          </FormControl>

          <FormControl>
            <HStack alignItems="flex-start">
              <Checkbox
                value={tnc}
                onChange={() => {
                  setTnc(!tnc);
                }}
              />

              <Text ml={2} fontSize="sm">
                By clicking the PAY NOW button below, I hereby agree to and
                accept the terms and conditions governing the use of the
                information collected on Donewelllife.com.gh website.
              </Text>
            </HStack>
          </FormControl>

          <Image src={telco} alt="telco" resizeMode="contain" w="80%" h="10" />

          <Button
            onPress={() => {
              validateFields();
            }}
          >
            PAY NOW
          </Button>
        </Stack>
        <ToastContainer />
      </Box>
    </div>
  );
}

const BackgroundImage = styled.div`
  background: url("${bg}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -100;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

//  Swal.fire({
//    text: "Enter your mobile money number to get an OTP to validate your payment",
//    input: "text",
//    imageUrl:
//      "https://raw.githubusercontent.com/phwed/ASSETS-REPO/main/dl/69-691715_mtn-mm-logo-generic-mtn-mobile-money-logo.png",
//    imgageWidth: 100,
//    imageHeight: 100,
//    inputAttributes: {
//      autocapitalize: "off",
//    },
//    showCancelButton: true,
//    confirmButtonText: "Get OTP",
//    confirmButtonColor: "#5e7c04",
//    cancelButtonColor: "#d33",
//    showLoaderOnConfirm: true,
//    allowOutsideClick: false,
//    preConfirm: (value) => {
//      if (!value) {
//        Swal.showValidationMessage(`Please provide a phone number`);
//        return;
//      } else if (value !== phone) {
//        Swal.showValidationMessage(
//          `Your mobile number does not match, the number should be in the format 233240000000`
//        );
//        return;
//      }

//      const pathParams = new URLSearchParams({
//        clientPhone: value,
//        amount: amount,
//        frequencyType: paymentFrequency,
//        frequency: frequency,
//        startDate: startDate,
//        endDate: endDate,
//        debitDay: debitDay,
//        policyNumber: policy,
//        email: email,
//        product: product,
//        fname: policies[0].firstName,
//        lname: policies[0].lastName,
//        Isverify: "no",
//      });

//      return fetch(`${process.env.REACT_APP_MANDATE_URL}${pathParams}`)
//        .then((response) => {
//          if (!response.ok) {
//            throw new Error(response.statusText);
//          }
//          return response.json();
//        })
//        .catch((error) => {
//          Swal.showValidationMessage(`Request failed: ${error}`);
//          console.log(error);
//        });
//    },
//  }).then((result) => {
//    if (result.isConfirmed) {
//      console.log(result);
//      Swal.fire({
//        title: `${result.value.data.responseMessage}`,
//        text:
//          result.value.data.responseCode === "03" &&
//          "Check your phone to enter your PIN to validate mandate",
//        icon: result.value.data.responseCode === "112" ? "error" : "success",
//      });
//    }
//  });
