import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Center,
  Input,
  Button,
  FormControl,
  Image,
  Stack,
  SearchIcon,
  Heading,
  Text,
  HStack,
  Pressable,
} from "native-base";
import bg from "../assets/background.png";
import styled from "styled-components";
import FullHeight from "react-full-height";
import logo from "../assets/logo.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function PolicyVerificationPage() {
  const [inputValue, setInputValue] = useState("");
  const [policyHolderData, setPolicyHolderData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchUserDetails = async (param) => {
    const pathParam = new URLSearchParams({
      search: param,
    });

    fetch(
      "https://app.pinnaclelife.com.gh/DonewellLife/GetClient?" +
        pathParam
    )
      .then((response) => response.json())
      .then((data) => {
        setPolicyHolderData(data.data);
        !data.success &&
          toast.error("No policy holder was found", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  };

  const validateFields = () => {
    if (inputValue === "") {
      toast.warn("Please input a policy number or a phone number", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    } else if (inputValue.length < 5) {
      toast.warn("Please input a valid policy number or phone number!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    return true;
  };

  return (
    <FullHeight
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BackgroundImage />
      <Center
        // h="80%"
        w={["95%", "90%", "80%", "60%"]}
        bg="white.500"
        rounded={5}
        shadow={5}
        py={20}
      >
        <Image
          source={logo}
          alt="logo"
          accessibilityLabel="logo"
          w="40vh"
          h="10vh"
          resizeMode="contain"
        />

        <Heading mt={5} fontSize="2xl">
          ONLINE PAYMENT
        </Heading>
        {/* <Text>
          Kindly set a new secret code/pin that would be used to verify your
          statement request
        </Text> */}

        <Stack w={["90%", "80%", "70%", "50%"]} mt={10}>
          <FormControl isRequired>
            <FormControl.HelperText _text={{ fontSize: "sm" }}>
              Enter policy number or phone number
            </FormControl.HelperText>
            <Input
              mt={2}
              //   borderColor="primary.900"
              variant="filled"
              rounded="lg"
              size="lg"
              placeholder="DL1P0012345"
              placeholderTextColor="black.400"
              value={inputValue}
              onChangeText={(value) => setInputValue(value)}
              InputRightElement={<SearchIcon size="sm" mx={5} />}
            />

            <Button
              size="lg"
              rounded="lg"
              isLoading={isLoading}
              mt={5}
              onPress={() => {
                if (validateFields()) {
                  setIsLoading(true);
                  fetchUserDetails(inputValue);
                } else {
                  console.log("validation failed");
                }
              }}
            >
              VERIFY POLICY HOLDER
            </Button>
          </FormControl>
        </Stack>

        {/* results rendered when found */}
        {policyHolderData.length ? (
          <Center
            bg="gray.100"
            p={5}
            mt={5}
            rounded="lg"
            w={["90%", "80%", "70%", "50%"]}
          >
            <Heading fontSize="lg" textAlign="center">
              Policy Holder Found
            </Heading>
            <Center my={5}>
              <Text textAlign="center" fontWeight="200">
                Would you like make payment for{" "}
              </Text>
              <Text color="blue.500" fontWeight="bold" fontSize="lg">
                {policyHolderData[0].fullName} ?
              </Text>
            </Center>
            {/* buttons */}
            <HStack alignItems="center" justifyContent="space-around" w="100%">
              <Pressable
                onPress={() => {
                  setPolicyHolderData([]);
                  setInputValue("");
                }}
                style={buttonRed}
              >
                <Text color="white.300">CANCEL</Text>
              </Pressable>

              <Link
                to={{
                  pathname: "/pay-premium",
                  state: policyHolderData,
                }}
                style={buttonGreen}
              >
                MAKE PAYMENT
              </Link>
            </HStack>
          </Center>
        ) : null}
        <ToastContainer />
      </Center>
    </FullHeight>
  );
}

const BackgroundImage = styled.div`
  /* background: url("${bg}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -100;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; */
`;

const buttonRed = {
  textDecoration: "none",
  borderRadius: 5,
  fontFamily: "Open Sans",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  color: "white",
  backgroundColor: "red",
};

const buttonGreen = {
  textDecoration: "none",
  borderRadius: 5,
  fontFamily: "Open Sans",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  color: "white",
  backgroundColor: "green",
};
